"use strict";

// Application imports
import * as Cyphrme from './cyphrme.js';
import * as Login from './login.js';
import * as Ajax from './ajax.js';
import * as CVA from './cva.js';
import * as Dragster from './dragster.js';

export {
	// Images
	ImageOnload,
	DisplayImages,

	// Files
	FileOnload,
	DisplayFiles,
	UploadFile,

	// Both
	DeleteFile,
	DownloadTxt,
	GetFileExtension,
	IsImage,
};

/**
@typedef {import('../page/image.js').ImagePage}               ImagePage
@typedef {import('../../../pkg/cozejs/typedef.js').B64}       B64
*/

/**
A File is an image or file object, that should mirror the `FileStore` model in
the server, with any additional fields/data needed for the GUI.

id: The B64 ID of the image (from the czd).
uad: The B64 ID of the user that owns the image.
ext: The extension of the image.
dig: The digest of the image.
order: The order of precedence for the image, respective to the other images on
the page.
parent: The parentID that the image is attached to.
root: The root/core subject matter that the image is on.
time: The time the image was inserted into the database.
updated: The time the image was last updated.
uad: The UAD of the image.
utk: The UTK time index for the image.
ht: The HT (Hash Time).
deleted: Whether the record has been marked deleted in the database.
is_image: Whether or not the file is an image.
@typedef  {object}     File
@property {B64}        id
@property {B64}        uad
@property {string}     ext
@property {B64}        dig
@property {number}     [order]
@property {B64}        root
@property {B64}        [parent]
@property {number}     time
@property {number}     updated
@property {B64}        uad
@property {B64}        utk
@property {HT}         ht
@property {boolean}    [deleted]
@property {boolean}    is_image
 */

/**
@typedef  {object.<B64, File>}     ImageCzdObj  - Holds images on the page.
 */

/**
@typedef  {object.<B64, File>}     FileCzdObj  - Holds files on the page.
 */


/////////////////
// Variables
////////////////
var DragsterLoaded = false;

/** @type {ImagePage} ImagePage */
var ImagePage
var CurrentImageDig;
var CurrentImageID;
var CurrentImageRoot;
var ImageLimit = 20; // Page limit for images. For full screen, should be 1 row of 4.
var imageModal; // The Bootstrap modal instance.
var Ime; // The Image Modal HTML Element.  
var ImageCzdObj = {}; // Holds all of the images on the page.

/** @type {FilePage} FilePage */
var FilePage;
var FileCzdObj = {}; // Holds all of the files on the page.
var FileLimit = 20; // Page limit for files. For full screen, should be 1 row of 3.

/**
ImageOnLoad initializes the javascript for uploading and performing other
image actions on the page.
// TODO better naming or explanation why ImageOnload is different from FileOnload
@param   {ImagePage} imagePage
@returns {void}
 */
async function ImageOnload(imagePage) {
	console.debug("Executing ImageOnload")
	if (!DragsterLoaded) {
		await Dragster.DragsterSetCallback(uploadImage)
		Dragster.DragsterOnload()
		DragsterLoaded = true
	}
	if (isEmpty(imagePage)) {
		console.log("ImagePage is empty.")
		return
	}
	DisplayImages(imagePage)
}

/**
FileOnload initializes the javascript for uploading, deleting, and displaying
files on the page.
// TODO better naming or explanation why ImageOnload is different from FileOnload
@param   {FilePage} filePage
@returns {void}
 */
async function FileOnload(filePage) {
	console.debug("Executing FileOnload");
	if (!DragsterLoaded) {
		await Dragster.DragsterSetCallback(uploadImage);
		Dragster.DragsterOnload();
		DragsterLoaded = true;
	}

	// Set event listeners in case files are added later.
	// Delete files button.
	var delBtn = document.querySelector(".acFilesDeleteBtn")
	if (delBtn !== null) {
		delBtn.addEventListener('click', () => {
			for (let l of document.querySelectorAll('#cy_file_gallery li')) {
				if (l.querySelector('input').checked) {
					DeleteFile(l.id);
				}
			}
		})
	}

	if (isEmpty(filePage)) {
		console.log("FilePage is empty.");
		return;
	}
	FilePage = filePage;
	DisplayFiles();
};

/**
DisplayImages displays all of the images on a given page. This function
should be called in the on load function. This checks to see if
ImagePage.images is empty, and if not, it displays them on the page.
@param   {ImagePage}  imagePage
@returns {void}
 */
function DisplayImages(imagePage) {
	// Set global here, and not onload. For some reason, the global shows
	// undefined when set in onload, and when passed, this function recognizes it.
	// The file onload sets FilePage in the onload, and it works as expected.
	// WTF?
	ImagePage = imagePage
	if (isEmpty(ImagePage.images)) {
		console.log("ImagePage does not have any images.");
		return;
	}

	// WTF
	if (!isEmpty(ImagePage.paginate.limit)) {
		ImageLimit = ImagePage.paginate.limit;
	}

	// "Show more images".  Applies to modal and everything else.  
	if (!isEmpty(ImagePage.has_more)) {
		let btns = document.querySelectorAll('.ShowMoreImagesBtn');
		console.log(btns);
		for (let btn of btns) {
			console.log(btn)
			Show(btn);
			console.log("Link", Cyphrme.Page.Images + "/" + ImagePage.images[0].id + "?limit=20");
			btn.href = (Cyphrme.Page.Images + "/" + ImagePage.images[0].id + "?limit=20");
		}
		// let btn = Ime.querySelector('#ShowMoreImagesBtn');

	}


	Ime = document.getElementById('imageModal');
	imageModal = new bootstrap.Modal(Ime, {
		keyboard: true
	}); // imageModal is the Bootstrap instance, not html element. 

	// Set click navigation on arrows on modal.
	Ime.querySelector('.modalArrowLeft').addEventListener('click', function() {
		setModalArrows(true);
	});
	Ime.querySelector('.modalArrowRight').addEventListener('click', function() {
		setModalArrows(false);
	});

	// Set keyboard navigation on modal. 
	var keyArrows = function(event) {
		if (event.keyCode == 37) {
			setModalArrows(true);
		}
		if (event.keyCode == 39) {
			setModalArrows(false);
		}
	};

	Ime.addEventListener('show.bs.modal', function(e) {
		document.addEventListener('keydown', keyArrows);
	});
	Ime.addEventListener('hide.bs.modal', function(e) {
		document.removeEventListener('keydown', keyArrows);
	});

	Ime.querySelector('.modal_delete_image').addEventListener('click', () => DeleteFile());


	// For image gallery
	for (let i in ImagePage.images) {
		ImageCzdObj[ImagePage.images[i].id] = ImagePage.images[i]; // Set ImageCzdObj

		if (i == ImageLimit) {
			break;
		}
		appendTmbImageToGallery(ImagePage.images[i]);
	}
}

/**
DisplayFiles displays all of the files (non images) on a given page. This
function should be called in the on load function. This checks to see if
FilePage.files is empty, and if not, it displays them on the page, while
also setting event listeners.
@returns {void}
 */
async function DisplayFiles() {
	// console.debug("Display Files");

	if (isEmpty(FilePage.files)) {
		console.log("FilePage does not have any files.");
		return;
	}

	// WTF
	if (!isEmpty(FilePage.paginate.limit)) {
		FileLimit = FilePage.paginate.limit;
	}

	// For file gallery
	let colCounter = 0;
	let cols = document.querySelectorAll("#cy_file_gallery .col");
	let fileCounter = 0;
	for (let i in FilePage.files) {
		FileCzdObj[FilePage.files[i].id] = FilePage.files[i]; // Set FileCzdObj

		if (i == FileLimit) {
			break;
		}

		appendTmbFileToGallery(FilePage.files[i], cols[colCounter]);
		// For placing files in alternating columns.
		if (colCounter == cols.length - 1) {
			colCounter = 0;
		} else {
			colCounter++;
		}
		fileCounter++;
	}
	// Set GUI based on whether or not user on page is the owner.
	if (fileCounter > 0) {
		if (FilePage.uad === Login.UAD) {
			Show(document.querySelector(".acFilesDeleteBtn"));
		} else {
			for (let div of document.querySelectorAll('#cy_file_gallery .form-check-input')) {
				div.parentElement.classList.remove('form-check');
				Hide(div);
			}
		}
		Show("cy_file_gallery");
	}
}

/**
Appends the file thumbprint the the GUI file gallery.
@param   {File}        file
@param   {HTMLElement} listDiv  HTML ul element for appending file to.
@returns {Void}
 */
async function appendTmbFileToGallery(file, listDiv) {
	// console.debug("File: ", file, "Div: ", listDiv);
	let li = document.createElement('li');
	let a = document.createElement('a');
	a.target = "_blank"; // Open in new tab.
	a.rel = "noopener noreferrer"; // Open in new tab.
	a.href = Cyphrme.API.Get.File + "/" + file.dig;
	a.textContent = file.file_name;
	li.id = file.id;
	let check = document.createElement('div');
	check.classList.add('form-check');
	let input = document.createElement('input');
	input.classList.add('form-check-input');
	input.type = "checkbox";

	check.append(input);
	check.append(a);
	li.append(check)
	listDiv.querySelector('ul').append(li);
	Show("cy_file_gallery"); // Always show, in case it is hidden.
}

/**
Appends the image thumbprint to the GUI image gallery.
@param   {Image}     image
@returns {Void}
 */
function appendTmbImageToGallery(image) {
	let imageDiv = document.getElementById('image_template').content.cloneNode(true);
	let img = document.createElement('img');
	let src = Cyphrme.API.Get.Image + '/' + image.dig;
	img.id = image.id;

	if (image.ext === "svg") { // svg's don't have thumbnails. 
		src += '.svg';
		img.dataset.imagedig = image.dig;
	} else {
		src += '-TN1';
		img.dataset.imagedig = image.dig + '-TN1';
	}
	img.src = src;
	img.classList.add('cyphrImg', 'imgThumb');
	img.addEventListener('click', function() {
		setImageModal(img.id);
		imageModal.toggle();
	});

	imageDiv.querySelector('.image_container').append(img);
	document.querySelector('#cy_image_gallary .row').append(imageDiv);
}

/**
setImageModal accepts an id, and the img Element being modified.
This function modifies the Modal/element for the new id/image passed in.
@param   {B64}  czd  - czd of the new image.
@returns {void}
 */
function setImageModal(czd) {
	CurrentImageDig = ImageCzdObj[czd].dig;
	CurrentImageID = czd;
	CurrentImageRoot = ImageCzdObj[czd].root;

	var img = document.getElementById('imageModalImage');
	img.dataset.imageid = czd;
	img.dataset.imagedig = ImageCzdObj[czd].dig;
	img.setAttribute('src', Cyphrme.API.Get.Image + '/' + ImageCzdObj[czd].dig);


	Ime.querySelector('.modal_delete_image').hidden = !(Login.UAD === ImageCzdObj[czd].uad);
	Ime.querySelector('.modal_image_action').href = "/e/" + CurrentImageID;
	Ime.querySelector('.modal_image_root').href = "/s/" + CurrentImageRoot;
}

/**
setModalArrows determines the index of the current image in the modal, relative
to the order in which they are loaded and displayed on the page. Then, depending
on the param passed in (true or false), the modal is modified accordingly.
Passing True to this function is assuming the left button was clicked and the
previous image is loaded. If clicking previous and the image is the first image
in the list, the last image is pulled up. Passing False to this function assumes
the right button was clicked.

@param   {boolean} left         True if left button, and false, if right.
@returns {void}
 */
function setModalArrows(left) {
	let czdKeys = Object.keys(ImageCzdObj);
	if (czdKeys.length == 1) { // If there's only one, return.
		return;
	}

	let ind = czdKeys.indexOf(CurrentImageID);
	let l = ind;
	let r = ind;

	if (czdKeys.length > ImageLimit) {
		czdKeys = czdKeys.slice(0, ImageLimit);
	}
	if (ind === 0) {
		l = czdKeys.length;
	} else if (ind === (czdKeys.length - 1)) {
		r = -1;
	}
	//// Debugging
	// console.debug(l);
	// console.debug(r);
	// console.debug(czdKeys.length);

	// Prevents infinite loop, if the modal still somehow loaded without any images
	// left on the page. Sanity check to prevent bugs.
	if (czdKeys.length == 0) {
		return;
	}

	// Loop is to account for multiple images that may be removed from the page.
	// The loop continues until if finds the next image.
	if (left) { // Left arrow
		while (true) {
			if (isEmpty(czdKeys[l - 1])) {
				l--;
			} else {
				break;
			}
		}
		setImageModal(czdKeys[l - 1]);
	} else { // Right arrow
		while (true) {
			if (isEmpty(czdKeys[r + 1])) {
				r++;
			} else {
				break;
			}
		}
		setImageModal(czdKeys[r + 1]);
	}
}

////////////////////////////////////////////////////////////////////////////////
// File Helpers
////////////////////////////////////////////////////////////////////////////////

/**
GetFileExtension accepts a file.name and return the extension, as a string.
@param   {string} filename    File name
@returns {string} ext         File extension
 */
function GetFileExtension(filename) {
	let parts = filename.split('.')
	if (parts.length < 2) {
		throw new Error("helpers.GetFileExtension: file does not have extension")
	}
	return parts[parts.length - 1]
}

/**
IsImage accepts a file and return the extension, as a string.
@param   {string} filename    File name
@returns {boolean}            If file is an image
 */
function IsImage(fileName) {
	return ["jpeg", "jpg", "png", "gif"].includes(GetFileExtension(fileName))
}

/**
This function accepts data to be written to a file, and the filename for
the file. Writes to the file, and downloads with the given name.
@param   {string} txt        Data being written to the file.
@param   {string} filename   Filename for file being downloaded.
@returns {void}
 */
function DownloadTxt(txt, filename) {
	let downloadLink = document.createElement("a") // Download link
	downloadLink.download = filename // File name
	downloadLink.href = window.URL.createObjectURL(new Blob([txt], {
		type: "text/txt"
	})) // Create a link to the file
	downloadLink.style.display = "none" // Make sure that the link is not displayed
	document.body.appendChild(downloadLink) // Add link to DOM
	downloadLink.click() // Simulate a click.
}

/**
getFileFromPath returns a file blob from a given path.
@param   {string} path    path to file (including relative)
@returns {Blob}
 */
async function getFileFromPath(path) {
	let response = await fetch(path)
	return response.blob()
}

////////////////////////////////////////////////////////////////////////////////
// AJAX Section
////////////////////////////////////////////////////////////////////////////////

/**
UploadFile creates an upload coze and uploads file data and coze. File may be
generic file or image file.  
// TODO rename parsd to rjson
@param   {File}   file  file is the file being uploaded.
@returns {{coze,parsd}} // File creation coze and the parsed response from the server.
@throws
 */
async function UploadFile(file) {
	if (isEmpty(Login.UAD)) {
		Cyphrme.Error("error: must be logged in to upload images")
		return
	}
	if (file.size > Cyphrme.MaxFileSize) {
		Cyphrme.Error("error: The file is too large. Must be under 30 mb.")
	}

	try {
		console.log("UploadFile", file, ImagePage);
		if (isEmpty(ImagePage)) {
			var coze = await CVA.FileCreate(file)
		} else {
			var coze = await CVA.FileCreate(file, ImagePage.id, ImagePage.child_ac)
		}
		let formData = new FormData()
		formData.append('file', file)
		formData.append('coze', JSON.stringify(coze))
		var parsd = await Ajax.FetchPost(Cyphrme.API.Post.File, formData)
	} catch (e) {
		console.error(e)
	}
	return {
		coze: coze,
		parsd: parsd
	}
}


/**
uploadImage is the callback function for image uploading. Adds the
images to the page on a successful upload, and show a notification message with
the appropriate error message, if the image upload is not successful.
@param   {File}   file  file is the file being uploaded.
@returns {void}
 */
async function uploadImage(file) {
	let cp = await UploadFile(file)

	//console.debug("uploadImage cp: ", cp)
	let image = cp.parsd.obj
	ImageCzdObj[cp.coze.czd] = {
		"id": cp.coze.czd,
		"dig": cp.coze.pay.id
	}

	// Files
	if (!image.is_image) {
		appendTmbFileToGallery(image, document.querySelector("#cy_file_gallery .col"))
		document.getElementById(image.id).scrollIntoView()
		Show(document.querySelector('.acFilesDeleteBtn')) // Always show, since only owners can upload files.
		Cyphrme.Notification("Successfully uploaded file!", 'success')
		return
	}

	// Images
	document.getElementById('file_drop_area').querySelector('.card').classList.add('border-success')
	appendTmbImageToGallery(image)
	document.getElementById(image.id).scrollIntoView()
	Cyphrme.Notification("Successfully uploaded image!", 'success')
}


/**
DeleteFile sends a form to the server to delete the file with the
associated ID/Czd.

If czd isn't populated, uses "Current"
@param   {B64} [czd]      Czd of the file.
@returns {void}
 */
async function DeleteFile(czd) {
	if (isEmpty(czd)) {
		czd = CurrentImageID;
	}
	if (isEmpty(Login.UAD)) {
		Cyphrme.Error('Account ID not set.');
	}

	let formData = new FormData();
	formData.append('cozes', "[" + JSON.stringify(await CVA.FileDelete(czd)) + "]");
	let parsd = await Ajax.FetchPost(Cyphrme.API.Post.ImageDelete, formData)
	// console.debug("Parsd: ", parsd)
	if (isEmpty(parsd)) {
		return
	}

	document.getElementById(parsd.obj).remove()

	if (parsd.obj in ImageCzdObj) {
		delete ImageCzdObj[parsd.obj] // Delete from ImageCzdObj
	}
	if (parsd.obj in FileCzdObj) {
		delete FileCzdObj[parsd.obj] // Delete from FileCzdObj
	}
	if (document.querySelectorAll('#cy_file_gallery li').length <= 0) {
		Hide("cy_file_gallery") // TODO kill cy
	}
	Cyphrme.Notification("Successfully deleted image.", 'success')
}

//// Deprecated. Supported images supported by the GO server, and determines
// whether or not to display as a file, or an image.
// /**
//  * uploadImage uploads an image for supported image file types.
//  * 
//  * @param   {File}   file      Image being uploaded.
//  * @param   {number} i         i number for the upload's progress bar.
//  * @returns {void}
//  * @throws  {error}            Fails when file is not a supported image.
//  */
// async function uploadImage(file, i) {
// 	let ext = GetFileExtension(file.name);
// 	// TODO svg's are not displaying in html for unknown reason.  If that works,
// 	// support svg.  Everything else with svg tested.
// 	if (["jpeg", "jpg", "png", "gif"].includes(ext)) {
// 		UploadFile(file, i);
// 		return;
// 	}
// 	Cyphrme.Error("unsupported image type " + ext);
// };