"use strict"

import * as Cyphrme from './cyphrme.js'
import * as Cva from './cva.js'
import * as Ajax from './ajax.js'
import * as Comment from './comment.js'
import * as Wallet from './wallet.js'

import * as Coze from '../pkg/coze_all~fv=-7uCIHNa.min.js'
import '../pkg/urlform~fv=VhWyOSvq.min.js' // Namespaced as 'URLForm'.

/**
@typedef {import('../../../pkg/cozejs/typedef.js').Pay}             Pay
@typedef {import('../../../pkg/cozejs/typedef.js').Coze}            Coze
@typedef {import('../../../pkg/cozejs/typedef.js').Key}             Key
@typedef {import('../../../pkg/cozejs/typedef.js').PublicCozeKey}   PublicCozeKey
@typedef {import('../../../pkg/cozejs/typedef.js').PrivateCozeKey}  PrivateCozeKey
@typedef {import('../../../pkg/cozejs/typedef.js').B64}             B64
@typedef {import('../../../pkg/cozejs/typedef.js').Time}            Time
@typedef {import('../../../pkg/cozejs/typedef.js').Tmb}             Tmb
 */

/**
AccountDetails holds the account information for a user.

- id:              ID of the account. Should match 'uad' after activation.
- uad:             Owner of the account (notary until user activation).
- activated:       Time when the user first logged in to their account on Cyphr.me.
- backup:          If any account key has been backed up.  Used to make sure users cannot get locked out of their account.  
- notary:          User that added this user to the system.
- display_name:    Display name for the account.
- updated:         Last time the account was updated.
- first_name:      User's first name.
- last_name:       User's last name.
- address_1:       User's (home) address.
- address_2:       User's second (home) address.
- country:         Country user resides in.
- invtes:          How many user invites this account has.
- email:           Email address for the account.
- phone_1:         Phone number for the account.
- phone_2:         Second phone number for the account.
- city:            City for the account.
- state:           State for the account.
- zip:             Zip/area code for the account.
- email_recovery:  Can the account be recovered through email?
- email_verified:  The primary email in use has been verified.
@typedef  {object}  AccountDetails
@property {B64}     id
@property {B64}     uad
@property {Time}    activated
@property {Bool}    backup 
@property {B64}     notary
@property {string}  [display_name]
@property {Time}    [updated]
@property {string}  [first_name]
@property {string}  [last_name]
@property {string}  [address_1]
@property {string}  [address_2]
@property {string}  [country]
@property {number}  [invites]
@property {string}  [email]
@property {string}  [phone_1]
@property {string}  [phone_2]
@property {string}  [city]
@property {string}  [state]
@property {string}  [zip]
@property {boolean} [email_recovery]
@property {boolean} [email_verified]
 */

/**
Profile is the user updatable AccountDetails fields for a user's account.
** 'id' is required in the object, and is not updatable. **
See `AccountDetails`.

- display_name:  Defaults to the abbreviated id (e.g. cLj8vs...).

Rest of the fields are self explanatory and fairly standard for most user forms.
Caveats, such as max lengths, restrictions, etc. should be doc'd if relevant for
the field.
@typedef   {object} Profile
@property  {B64}    id
@property  {string} [display_name]
@property  {string} [first_name]
@property  {string} [last_name]
@property  {string} [email]
@property  {string} [address_1]
@property  {string} [address_2]
@property  {string} [phone_1]
@property  {string} [phone_2]
@property  {string} [city]
@property  {string} [state]
@property  {string} [zip]
@property  {string} [country]
 */

/**
Accounts holds a key:value pair with the name of the account as the key, and
the AccountDetails object for the account as the value.
@typedef  {object} Accounts
 */


Cyphrme.AddOnloadFirst(LoginOnload)

export {
	UAD,
	Accounts,
	AccountDetails,
	CozeKey,
	CozeKeyPublic,
	CozeKeyExtra,
	Keys,

	LS_AccountDetails,
	LS_Keys,
	LS_SelectedKey,
	LS_Accounts,

	AccountDetailsCallback,
	ClearLocalStorage,
	CozeKeyNormal,
	DeleteLocalAccount,
	DeleteKey,
	GetProfile,
	GlobalNewKey,
	InitAccounts,
	InitFromStorage,
	InitKeys,
	IsUserBackedUp,
	Login,
	Logout,
	IsLoggedIn,
	CreatePreInviteEmailForm, // Email onboard
	SetLocalAccount,
	SetSelectedKey,
	SetLocalAccounts,
	UpdateKid,
	UpsertGlobalKey,
	UpsertGlobalKeys,
	UpdateLocalAccountDisplayName,
	UpdateAccountDisplayNames,
	UpsertGlobalAccounts,
	UpdateAccount,
	VerifyAccess,
}

////////////////////////////////////////////////////////////////////////////////
// Global vars set by the application
////////////////////////////////////////////////////////////////////////////////

// Why both Coze and CryptoKeys?  Because Coze is used for human and wire
// transmission. CryptoKey are used to actually perform Crypto. Both need to be
// set for the application to work as expected.

/** UAD is the unique identifier used for users accounts. It might not be a
valid key tmb and instead may be a `wad` "wallet digest"
 * @type {B64} **/
var UAD = ""

/** @type {AccountDetails} **/
var AccountDetails = {} // Account details object. All details in a user's "account".

/** @type {PrivateCozeKey} **/
var CozeKey = {} // The selected Key. 

/** @type {PrivateCozeKey} **/
var CozeKeyExtra = {} // The select key with any "extra" fields like `"SerializedTmb":"ES256:b3Td"`

/** @type {PublicCozeKey} **/
var CozeKeyPublic = {} // Public Coze Key javascript object.

// TODO typedef
var Keys = {} // All user keys.

/** @type {Accounts} **/
var Accounts = {} // All accounts stored locally.

// LoginKeySuccess is a global to avoid callback hell on login. 
var LoginKeySuccess

var preInviteModal = "" // Bootstrap HTML modal for pre-invites.

// Error enum from server when pre-invite account has already been created and
// a replay is attempted.
const errPreInviteAccountAlreadyCreated = "Pre invite account has already been created, you must login from the wallet page."


////////////////////////////////////////////////////////////////////////////////
// Local Storage
////////////////////////////////////////////////////////////////////////////////

// "Current" or private key being used to sign things. It is the default, or
// currently selected key used for interacting with the application.
const LS_SelectedKey = "selected_key"
const LS_Keys = "keys" // All keys in local storage, including non uploaded keys.
const LS_AccountDetails = "account_details" // The current local account details.
const LS_Accounts = "accounts" // All of the different local accounts.


async function LoginOnload() {
	console.log("LoginOnload")
	await InitFromStorage()
	// Add account to links on the page 
	for (var l of document.querySelectorAll('.append_account_to_link')) {
		if (!isEmpty(UAD)) {
			l.href += "/" + UAD
		} else {
			Hide(l)
		}
	}
	preinviteAndBackupPesterModal()

	//// Login Debug:
	// console.log("UAD: ", UAD)
	// console.log("AccountDetails: ",  JSON.stringify(AccountDetails))
	// console.log("CozeKey: ",  JSON.stringify(CozeKey))
	// console.log("CozeKeyPublic: ", JSON.stringify(CozeKeyPublic))
}

/**
Login uses a Coze Key to generate a login request and send it to the server.

First time login generates "key_upsert" and should only be sent on first time
login requests. After that, it is redundant and not used.
{
"login_request": object of type `cyphr.me/user/login/create`,
"key_upsert": Object of type cyphr.me/key/upsert,
}

Optional formData allows additional fields to be sent along in the form.
See `generatePreInviteAccount` for an example.

TODO change login parameters to LoginObject, super type of UploadObj.
@param   {PrivateCozeKey}      cozeKey
@param   {boolean}             [firstTimeLogin]
@param   {function}            [callback]
@param   {FormData}            [formData]
@returns {void}
@throws  {error}
 */
async function Login(cozeKey, firstTimeLogin, callback, formData) {
	LoginKeySuccess = cozeKey
	if (isEmpty(formData)) {
		formData = new(FormData)
	}
	try {
		formData.append('login_request', await genLoginRequest(cozeKey))
		if (firstTimeLogin) {
			// Second cozeKey is used to sign the coze, instead of App CozeKey.
			formData.append('key_upsert', JSON.stringify(await Cva.KeyUpsert(cozeKey, cozeKey)))
		}

		await loginCallback(await Ajax.FetchPost(Cyphrme.API.Post.Login, formData), callback)
	} catch (e) {
		Cyphrme.Error(e)
	}
}

/**
generatePreInviteAccount checks for the `verify_preinvite_modal` keyword in the
URL Query, and if present, displays the Pre-Invite Account modal (sent by the
server), and calls login. Success/failures are displayed in the modal.
@returns {void}
 */
async function generatePreInviteAccount() {
	let quags = URLForm.GetURLKeyValue(URLForm.GetDefaultFormOptions())
	// console.log("generatePreInviteAccount quags", quags)
	if (!("email_verify" in quags)) {
		App.Error("Pre-Invite account generation started, but Coze not in URL.")
		return
	}
	// If user has not yet created a key, generate and set new key for signing.
	if (isEmpty(CozeKey)) {
		CozeKey = await GlobalNewKey(Coze.Algs.ES256)
		await SetSelectedKey(CozeKey)
	}

	// Send login create coze, as well as key upsert coze along with verification,
	// and cookie should be send back immediately so user is logged in and can
	// leave comment.
	let formData = new FormData()
	formData.append('email_verify', quags["email_verify"])
	Login(CozeKey, true, null, formData)
}


/**
loginCallback is the callback for login that can be performed on any page.
Parameter "callback" is called at the end of the function.
@param   {JSON}     resp       Server response
@param   {function} callback   Callback function
@returns {void}
 */
async function loginCallback(parsd, callback) {
	console.log("loginCallback", parsd)
	if (isEmpty(parsd)) {
		Cyphrme.Error("Login failed.")
	}
	if (!parsd.success) {
		// Check to see if error is from trying to replay the pre-invite account
		// creation login request.
		if (parsd.msg == errPreInviteAccountAlreadyCreated) {
			Show("closePreInvModalBtn") // In case the modal is still present, show the close button.
			preInviteModal.hide()
			return
		}
		Cyphrme.Error(parsd.msg) // Throws
	}

	try {
		//// Debugging
		// console.debug(parsd)
		// return

		// If any "create accounts" buttons are still visible, hide them.
		document.querySelectorAll(".createAccountBtn").forEach((elem) => {
			Hide(elem)
		})

		// Ensure user account was sent back in login response.
		if (isEmpty(parsd.obj.user)) {
			console.error("User's account was not sent back from the server response.")
			Cyphrme.Error("User not found in response.")
		}
		// Ensure login token was sent back in login response.
		if (isEmpty(parsd.obj.login_token)) {
			console.error("Login token was not sent back from the server response.")
			Cyphrme.Error("Login token not found in response.")
		}

		await SetLocalAccount(parsd.obj.user)
		await InitFromStorage()
		Cyphrme.Notification('Login successful.', 'success')
		// console.log(LoginKeySuccess)
		// Key.time is used downstream to know if a key has been added to Cyphr.me.  
		// Login responses do not send `key.time` so for first time logins use `iat`
		// for `key.time` and set it. It is set accurately when key is synced.
		if (isEmpty(LoginKeySuccess.time)) {
			LoginKeySuccess.time = parsd.obj.login_token.pay.iat
		}

		LoginKeySuccess.uad = parsd.obj.login_token.pay.uad
		await SetSelectedKey(LoginKeySuccess) // throws
		preinviteAndBackupPesterModal()

		// TODO move to comment.
		// Show review form div if template exists on page.
		if (document.querySelector('#submitReviewTemplate') !== null) {
			Comment.CreateSubmitReviewForm()
		}
		Hide("emailFormDiv")

		if (typeof callback == "function") {
			return callback(parsd)
		}
	} catch (error) {
		console.error("loginCallback: ", error)
		Cyphrme.Error(error)
	}
}

/**
Logout expires the current cookie, as well as the global application variables
(uad, cozekey, cozekey public), as well as the local browser storage for the
application (account_details). Takes an optional 'noRedirect' param that should
be passed as 'true' if needing to stay on the same page after logging out.
@param   {boolean} [noRedirect=false] If true, logout without redirecting to home.
@returns {void}
 */
async function Logout(noRedirect) {
	// console.debug(noRedirect)

	// Expire the cookie
	document.cookie = "login_token= expires=Thu, 01 Jan 1970 00:00:00 UTC path=/"
	// console.debug(document.cookie)
	UAD = null
	CozeKey = null
	CozeKeyPublic = null
	localStorage.removeItem(LS_AccountDetails)
	localStorage.removeItem(LS_SelectedKey)
	// Forces page refresh to home page.
	if (isEmpty(noRedirect) || !noRedirect) {
		window.location.href = "https://" + window.location.host
	}
	Cyphrme.Notification('Successfully logged out.', 'success')
}

/**
IsLoggedIn returns if the user is logged in as tracked by the local state.
@returns {boolean}
*/
function IsLoggedIn() {
	return (!isEmpty(UAD))
}

/**
genLoginRequest generates a new login request (for a token) with the given Coze
Key. Used by the user to send authenticated requests to the server. Returns
LoginRequestCoze as a string.

TODO maybe move to CVA?
@param    {PrivateCozeKey} cozeKey
@returns  {string}
@throws   {error}
 */
async function genLoginRequest(cozeKey) {
	let pay = {
		alg: cozeKey.alg,
		iat: Now(),
		tmb: cozeKey.tmb,
		typ: "cyphr.me/user/login/create",
	}
	let coze = await Coze.Sign({
			pay: pay,
		},
		cozeKey)
	// No private
	coze.key = {
		...cozeKey
	}
	delete coze.key.d
	return JSON.stringify(coze)
}


/**
preinviteAndBackupPesterModal checks if the pre-invite setup or pester modal
needs to be triggered.  It gets the pre-invite HTML via ajax so that that it
isn't loaded on all Cyphr.me pages.

Three testing cases:
1. Not logged in.
2. Page behavior on first time login
3. logged in.  
4. URLFormJS variable `verify_preinvite_modal`
@returns {void}
*/
async function preinviteAndBackupPesterModal() {
	console.log("preinviteAndBackupPesterModal IsUserBackedUp:", IsUserBackedUp())

	if ("verify_preinvite_modal" in URLForm.GetURLKeyValue()) {
		var hasPreInviteModal = true
	}
	// Hasn't made an account (no UAD), Not logged in, or not invite present and invite not used.
	if (isEmpty(UAD) || IsUserBackedUp() && !hasPreInviteModal) {
		return
	}
	console.warn("Calling Ajax PreInviteAccountModal.  This should only happen if user account is not backed up or URL variable verify_preinvite_modal is set.");
	let response = await Ajax.FetchHTML(Cyphrme.Page.PreInviteAccountModal)
	let div = document.createElement('div')
	div.innerHTML = response
	document.body.appendChild(div)
	preInviteModal = new bootstrap.Modal(document.querySelector('#preInviteAccountModal'))
	preInviteModal.show()

	if (hasPreInviteModal && !IsUserBackedUp()) {
		generatePreInviteAccount()
		return
	}

	// Sanitize Coze Key to include only minimum fields.
	let ck = {
		alg: CozeKey.alg,
		iat: CozeKey.iat,
		kid: CozeKey.kid,
		x: CozeKey.x,
		d: CozeKey.d,
		tmb: CozeKey.tmb,
		uad: UAD,
	}

	let accountModal = document.querySelector('#preInviteAccountModal')
	// console.log(accountModal)
	let modalContent = accountModal.querySelector('.modal-content')
	modalContent.querySelector('.newKey').textContent = JSON.stringify(ck, 0, 1)

	accountModal.querySelector('.copyBtn').addEventListener('click', () => {
		ClipboardE(modalContent.querySelector('.newKey'))
		Cyphrme.Notification("Copied!", "success")
	})

	accountModal.querySelector('.showPrivateKeyBtn').addEventListener('click', () => {
		ToggleVisible(accountModal.querySelector('.privateKey'))
	})

	if (isEmpty(AccountDetails.email)) {
		// if (isEmpty(AccountDetails.email) || isEmpty(AccountDetails.email_verified) || !AccountDetails.email_verified) {
		accountModal.querySelector('#emailBackup').checked = false
		Hide(accountModal.querySelector('.emailBackupRow'))
	} else {
		accountModal.querySelector('.emailSendAddress').textContent = AccountDetails.email
	}

	// TODO QR code for key
	Hide(accountModal.querySelector("#CreateSpinner"))
	Hide(accountModal.querySelector("#closePreInvModalBtn"))
	Show(accountModal.querySelector("#requiredBackup"))

	// Check if any private key is not currently backed up.
	InitKeys()
	var notBackedUp = {}
	var currentWallet = {}
	// console.debug("UAD: ", UAD, "Keys: ", Keys)
	for (let k in Keys) {
		let key = Keys[k]
		// Revokes keys cannot be updated, and only keys in the current logged in
		// wallet are checked.
		if (UAD === key.uad) {
			currentWallet[k] = key
		} else {
			continue
		}
		if (key.rvk > 0) {
			continue
		}
		if ((isEmpty(key.backup) || !key.backup) && !isEmpty(key.d)) {
			notBackedUp[k] = key
		}
	}
	// console.debug(notBackedUp)

	// If already backed up, do not display the backup pester options.
	if (Object.keys(notBackedUp).length <= 0) {
		document.querySelector("#BackupPesterHeader").textContent = "Your account is already backed up."
		Hide("BackupPester")
		Show("closePreInvModalBtn")
		// Set backup true on account.
		AccountDetails.backup = true
		SetLocalAccount(AccountDetails)
		return
	}

	accountModal.querySelector('#requiredPreInvBackupBtn').addEventListener('click', async () => {
		let atLeastOneChecked = false
		if (modalContent.querySelector('#emailBackup').checked) {
			if (isEmpty(AccountDetails.email)) {
				Cyphrme.Error('Email could not be found for account.')
				return
			}
			await Wallet.EmailWallet(AccountDetails.email, currentWallet)
			atLeastOneChecked = true
		}

		// Download all local keys.
		if (modalContent.querySelector('#downloadBackup').checked) {
			await Wallet.DownloadWallet(currentWallet)
			atLeastOneChecked = true
		}

		if (!atLeastOneChecked) {
			Cyphrme.Error("At least one backup option must be selected.")
		}
		// Send Key Upserts for marking keys as backed up.
		for (let k in notBackedUp) {
			let key = notBackedUp[k]
			key.backup = Now()
			Wallet.UpsertKey(key, async (parsd) => {
				Keys[parsd.obj.id].backup = parsd.obj.backup
				// Update keys within callback after each success, to ensure that
				// update does not occur before all ajax requests have completed.
				UpsertGlobalKeys()
				Cyphrme.Notification('Key has been updated', 'success')
			})
		}
		// Set backup true on account.
		AccountDetails.backup = true
		SetLocalAccount(AccountDetails)
		Show("closePreInvModalBtn")
	})
}



/**
IsUserBackedUp recalculates and returns whether the current user's local
state is backed up.
@returns {boolean} userIsBackedUp
 */
function IsUserBackedUp() {
	// Backups are stored on keys, and only on the user's account locally.
	// Recalculate whether the user's account state is backed up.
	// Only keys in the current logged in wallet is checked.
	InitKeys()
	// console.debug("UAD: ", UAD,"Keys: ", Keys)

	if (isEmpty(UAD)) {
		return false
	}

	// Accounts with no keys are considered to be backed up.
	if (Object.keys(Keys).length <= 0) {
		return true
	}
	let userIsBackedUp = true
	for (let k in Keys) {
		let key = Keys[k]
		// Revoked keys, thumbprint only keys, and keys not yet in the system do not
		// need to be backed up.

		// If no UAD, the key is not in the system and doesn't need to be backed up.
		if (isEmpty(key.uad) || UAD !== key.uad) {
			continue
		}
		// If key.time is not set, the key is not in the system and doesn't need to be backed up.  
		if (isEmpty(key.time) || key.time <= 0) {
			continue
		}
		if (key.rvk > 0) { // Revoked do not need backed up.  
			continue
		}
		if (key.niw) { // Key is not in the current wallet and not of concern.  
			continue
		}
		if (!isEmpty(key.backup) && key.backup > 0) {
			continue
		}
		console.debug("Key not backed up: ", key)
		userIsBackedUp = false
		break
	}

	return userIsBackedUp
}

/**
CreatePreInviteEmailForm generates the email invite form from a template, and
displays it on the page. Returns whether generation was successful.
@returns {boolean}
 */
function CreatePreInviteEmailForm() {
	let emailForm = document.getElementById('emailFormDiv')
	Show(emailForm)

	emailForm.querySelector('#submitEmailBtn').addEventListener('click', function (event) {
		submitPreInviteEmail()
	})
	// Shift + Enter should submit email.
	emailForm.querySelector('#reviewPreInvEmail').addEventListener('keydown', function (event) {
		if (event.code == "Enter" && event.shiftKey) {
			submitPreInviteEmail()
		}
	})
}

/**
submitPreInviteEmail submits a pre invite email creation request.
@returns {void}
 */
async function submitPreInviteEmail() {
	// Non-authenticated users are assumed to be pre-invited users.
	// NOTE: Email validation is done server side.
	let email = document.getElementById('reviewPreInvEmail').value
	let displayName = document.getElementById('preInviteDisplayName').value
	if (isEmpty(email)) {
		Cyphrme.Error("Email address is required.")
		return
	}
	let formData = new FormData()
	formData.append('email', email)
	formData.append('display_name', displayName)
	formData.append('referrer', Cyphrme.Site + window.location.pathname) // URL from where request originated from.
	try {
		let resp = await Ajax.FetchPost(Cyphrme.API.Post.EmailCreate, formData)
		console.debug("Response: ", resp)
		Cyphrme.Notification("Email was sent successfully. Check your inbox.", "success")
	} catch (error) {
		Cyphrme.Error(error)
	}
}

/**
InitFromStorage inits login storage vars.
Does not init keys, or accounts because keys/accounts is for wallet.
@returns {void}
 */
async function InitFromStorage() {
	try {
		await initSelected() // Init CozeKey, CozeKeyPublic from LS_SelectedKey
		await initAccountFromStorage() // Init Account Details from LS_AccountDetails
		// Don't call InitKeys since that should only be for the wallet page.
	} catch (e) {
		Cyphrme.Error(e) //throws error
	}

	if (isEmpty(UAD)) {
		console.debug("No account found for user.")
		return
	}

	if (isEmpty(AccountDetails)) {
		console.debug("No account details found for user.")
		return
	}

	//// GUI

	// Account Details
	// console.log(AccountDetails)
	let ocl = document.getElementById("one_click_login")
	let oclImage = '<i class="bi bi-person-circle"></i> Welcome '
	if (!isEmpty(AccountDetails.profile_picture)) {
		oclImage = '<img src="' + Cyphrme.API.Get.Image + "/" + AccountDetails.profile_picture + "-TN1" + '" class="prof_pic_sm"></img> Welcome '
	}

	ocl.querySelector('span').innerHTML = oclImage + AccountDetails.display_name.substring(0, 15)
	ocl.title = "Go to your account page"
	ocl.href = "/account"
}

/**
initSelected gets the selected private Coze key from local storage and inits
all related globals.
@exception {SyntaxError}  JSON parse exception. 
@returns   {void}
 */
async function initSelected() {
	let string = localStorage.getItem(LS_SelectedKey)
	if (isEmpty(string)) {
		console.debug('No selected key set.')
		return
	}
	CozeKey = JSON.parse(string)
	CozeKeyExtra = CozeKey
	CozeKey.SerializedTmb = CozeKey.alg + ":" + CozeKey.tmb
	CozeKeyPublic = JSON.parse(string)
	delete CozeKeyPublic.d
}

/**
initAccountFromStorage gets Account Details from storage, sets UAD globally.
@exception {SyntaxError}  JSON parse exception.
@returns   {void}
 */
async function initAccountFromStorage() {
	AccountDetails = {}
	let s = localStorage.getItem(LS_AccountDetails)
	if (!isEmpty(s)) {
		AccountDetails = JSON.parse(s)
		// If account has UAD, set it globally. 
		if (AccountDetails && AccountDetails.uad) {
			UAD = AccountDetails.uad
		}
	}
}

/**
InitAccounts gets accounts from storage and sets the global container variable.

If `Accounts` is already set, calling this function has no effect.
@exception {SyntaxError}  JSON parse exception.
@returns   {void}
 */
async function InitAccounts() {
	if (Object.keys(Accounts).length === 0) {
		let stringedAccts = localStorage.getItem(LS_Accounts)
		// Accounts are empty if not in storage. Don't attempt JSON.parse if
		// empty since it overwrites Keys with undefined.
		if (!isEmpty(stringedAccts)) {
			Accounts = JSON.parse(stringedAccts)
		}
	}
}

/**
initKeys gets keys from storage and sets the global container variable. If
`Keys` is already set, calling this function has no effect.
@exception {SyntaxError}  JSON parse exception.
@returns   {void}
 */
async function InitKeys() {
	if (Object.keys(Keys).length === 0) {
		let stringedKeys = localStorage.getItem(LS_Keys)
		// Keys are empty if not in storage. Don't attempt JSON.parse if empty since
		// it overwrites Keys with undefined.
		if (!isEmpty(stringedKeys)) {
			Keys = JSON.parse(stringedKeys)
		}
	}
}

/**
DeleteKey deletes the given key locally.
@param   {Tmb} id    ID for the given key being deleted.
@returns {void}
 */
async function DeleteKey(id) {
	delete Keys[id]
	UpsertGlobalKeys()
}

/**
DeleteLocalAccount deletes the given account locally. If the account is
the current logged in account, it is logged out.
@param   {UAD} uad    id/uad for the given account being deleted.
@returns {void}
 */
async function DeleteLocalAccount(uad) {
	delete Accounts[uad]
	UpsertGlobalAccounts()
	if (uad === UAD) {
		// Does not redirect to home after logging out. If needing to redirect, this
		// func must accept another param 'noRedirect'.
		Logout(true)
	}
}

/**
UpdateKid updates a kid for the given key locally.
@param   {Tmb}     tmb          ID for the key being updated.
@param   {string}  newKid       New `kid` for the key.
@returns {boolean}
 */
async function UpdateKid(tmb, newKid) {
	// console.log(tmb, newKid)
	// console.debug(Keys)
	Keys[tmb].kid = newKid
	// console.debug(Keys[tmb])
	localStorage.setItem(LS_Keys, JSON.stringify(Keys))
	return true
}

/**
SetLocalAccount sets/updates the current local account from the given account
details. The account is also set in the Accounts object.

Global application UAD variable are set.

Does nothing when no given account details are given.
@param   {AccountDetails}  account
@returns {void}
 */
async function SetLocalAccount(account) {
	if (!UpdateAccount(account)) {
		return
	}

	// Set current local account
	localStorage.setItem(LS_AccountDetails, JSON.stringify(account))
	AccountDetails = account
	UAD = account.uad
}

/**
UpdateAccount sets/updates the given account. If the account is already set, the
account details are overwritten with the given account details. Errors when
no given account details are given. Returns whether or not the account was
updated.
@param   {AccountDetails} account
@returns {boolean}
 */
async function UpdateAccount(account) {
	if (account == "") {
		return false // Do nothing
	}
	if (isEmpty(account)) {
		Cyphrme.Error("No Account information set.")
		return false
	}

	// Set/update all local accounts
	Accounts[account.id] = account
	SetLocalAccounts(Accounts)
	return true
}


/**
UpdateLocalAccountDisplayName updates the given account's display name. Given
account must have 'id' and 'display_name' populated. If the given account does
not exist in 'Accounts' yet, it is created.

Errors if account is empty, account.id is not a recognized Cyphrme hash, or if
account.display_name is empty.
@param   {Profile} account
@returns {void}
@throws  {error}
 */
async function UpdateLocalAccountDisplayName(account) {
	if (isEmpty(account) || !Cyphrme.IsCyphrmeDigest(account.id) || isEmpty(account.display_name)) {
		Cyphrme.Error("Given account does not meet the requirements for updating the display name.")
	}
	// For local `AccountDetails` (user account profile).
	AccountDetails.display_name = account.display_name
	localStorage.setItem(LS_AccountDetails, JSON.stringify(AccountDetails))
	// For local `Accounts` (wallet).
	if (isEmpty(Accounts[account.id])) {
		Accounts[accounts.id] = {}
	}
	Accounts[account.id].display_name = account.display_name
	SetLocalAccounts(Accounts)
}

/**
Update the given accounts with the display name. `accounts` should be in the
following form:
{
"cLj8vsYtMBwYkzoFVZHBZo6SNL8wSdCIjCKAwXNuhOk": "z",
"zxcLp3BEYYoAZxM9QlV7lS4o3Jn1T0dz9L0pWPZJnIs": "Jared"
}

If the account does not yet exist, it is created and set.
@param   {...Accounts} accounts    One or many user's account details.
@returns {void}
 */
async function UpdateAccountDisplayNames(accounts) {
	if (typeof accounts !== "object") {
		Cyphrme.Error("Accounts must be passed in as an object.")
	}
	for (let id in accounts) {
		// console.debug(id)
		if (isEmpty(Accounts[id])) {
			Accounts[id] = {}
		}
		Accounts[id].display_name = accounts[id]
	}
	SetLocalAccounts(Accounts)
}

/**
GetProfile returns an object with the updatable `AccountDetails` fields.
@returns {Profile}
 */
function GetProfile() {
	let fields = ["id", "display_name", "first_name", "last_name", "email", "address_1", "address_2", "phone_1", "phone_2", "city", "state", "zip", "country"]
	/**@type {Profile} */
	let p = {
		/** @protected */
		id: UAD
	}
	// Set Profile fields if they are not empty.
	for (let f of fields) {
		if (f === "id") {
			continue
		}
		if (!Coze.isEmpty(AccountDetails[f])) {
			p[f] = AccountDetails[f]
		}
	}
	return p
}


/**
Sets/updates the current local Accounts from the given accounts.
Errors when the given accounts is empty.
@param   {object}  accounts  Accounts object with 'uad' as the key, and the account object as the value.
@returns {void}
 */
async function SetLocalAccounts(accounts) {
	if (isEmpty(accounts)) {
		Cyphrme.Error("No Accounts given.")
		return
	}
	Accounts = accounts
	UpsertGlobalAccounts()
}

/**
SetSelectedKey sets the given private key to localStorage then calls init from
storage for that key.
@param   {PrivateCozeKey}  cz
@returns {boolean}
 */
async function SetSelectedKey(cz) {
	//console.debug("setSelectedKey")
	if (isEmpty(cz)) {
		Cyphrme.Error("Set Selected key: Key not set.")
		return
	}
	// console.log("Updating the app with Private CozeKey: " + cz)

	switch (typeof cz) { // Do this to avoid issues with escaping strings.
		case "object":
			break
		default: // Default case is assumed to be a serialized string.
			cz = JSON.parse(cz)
			break
	}

	localStorage.setItem(LS_SelectedKey, JSON.stringify(cz))
	initSelected() //Set Globals
	UpsertGlobalKey(cz)
	return true
}

/**
GlobalNewKey creates a new Coze Key with the given alg for the given account,
and updates the application with the new key.
@param   {Alg}             alg 
@returns {PrivateCozeKey}
 */
async function GlobalNewKey(alg) {
	let cz = await Coze.NewKey(alg)
	UpsertGlobalKey(cz)
	return cz
}

/**
UpsertGlobalKey upserts a Coze Key to the `Keys` global and updates local
Storage.
@param   {Key}  cz
@returns {void}
 */
async function UpsertGlobalKey(cz) {
	// console.debug("Adding Coze Key to Keys in Local Storage: ", cz)
	if (isEmpty(Keys)) {
		Keys = {}
	}
	Keys[cz.tmb] = cz
	UpsertGlobalKeys()
}

/**
UpsertGlobalKeys sets the "Keys" object in local storage.
@returns {void}
 */
async function UpsertGlobalKeys() {
	// console.debug("UpsertGlobalKeys", Keys)
	localStorage.setItem(LS_Keys, JSON.stringify(Keys))
}

/**
UpsertGlobalAccounts sets the "Accounts" object in local storage.
@returns {void}
 */
async function UpsertGlobalAccounts() {
	// console.debug("UpsertGlobalAccounts", Accounts)
	localStorage.setItem(LS_Accounts, JSON.stringify(Accounts))
}


/**
VerifyAccess sends a request to the server to check whether or not the UAD
has been verified by Cyphr.me. 
@returns {void}
 */
async function VerifyAccess() {
	if (isEmpty(UAD)) {
		Cyphrme.Notification("Account's UAD is not set", 'error')
		return
	}
	let formData = new FormData()
	formData.append('accountID', UAD)
	AccountDetailsCallback(await Ajax.FetchPost(Cyphrme.API.Get.User, formData))
}

/**
AccountDetailsCallback is the callback that accepts a response object which is
assumed to contain the identify information of the person loading the page. 

If the account is not verified by Cyphr.me, this function calls another function
that redirects them to an unverified account page. NOTE: That function should
then probably also store the response in some form, so that that user has
limited access to the application.
@param   {JSON} parsd
@returns {void} 
 */
async function AccountDetailsCallback(parsd) {
	if (isEmpty(parsd)) {
		return
	}
	console.log("Setting local account details")
	localStorage.setItem(LS_AccountDetails, JSON.stringify(parsd.obj))
	InitFromStorage()
}

/**
!! WARNING !! 
ClearLocalStorage clears all local storage for the Cyphr.me application.
A User should have all of their keys backed up/downloaded before calling
clearing local storage. Any checks to make sure that it is okay to clear
local storage should be made before calling this function.
@returns {void}
 */
function ClearLocalStorage() {
	console.warn('Clear local storage called.')
	localStorage.clear()
	Cyphrme.Notification('Successfully cleared local storage.', 'success')
}

////////////////////////////////////////////////////////////////////////////////
// Coze funcs outside of the CozeJS lib
////////////////////////////////////////////////////////////////////////////////

/** TODO out of date
Returns a normalized Coze key with "alg","iat","tmb',"x", and
if present, "kid", and "y".

Truncates `kid` at 50 characters and throws on oversized `iat`.
@param   {Key}    cozeKey
@returns {Key}
@throws  {error}  Throws if over JS max safe int.
 */
async function CozeKeyNormal(cozeKey) {
	var nck = {}
	nck.alg = cozeKey.alg
	if (cozeKey.iat > 9007199254740991) { // max safe Javascript integer.
		throw "Coze.Normal: `iat` too large"
	}
	nck.iat = cozeKey.iat
	if (!isEmpty(cozeKey.kid)) {
		nck.kid = cozeKey.kid.substring(0, 50) // `kid` soft limit of 50
	}
	nck.x = cozeKey.x
	if (Coze.Genus(cozeKey.alg) == "ECDSA") { // y is required for ECDSA
		nck.y = cozeKey.y
	}
	nck.tmb = cozeKey.tmb

	return nck
}